import React from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import ProjectDetails2Header from "components/Project-details2-header/project-details2-header";
import ProjectIntroductionSerco from "components/Project-introduction/project-introduction-serco";
import ProjectGallerySerco from "components/Project-gallery/project-gallery-serco";
import ProjectVideoSerco from "components/Project-video/project-video-serco";
import ProjectDate from "data/serco-details.json";
import DarkTheme from "layouts/Dark";

const Serco = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <div className="wrapper">
        <ProjectDetails2Header projectHeaderData={ProjectDate} />
        <ProjectIntroductionSerco projectIntroductionData={ProjectDate.intro} />
        <ProjectGallerySerco />
        <ProjectVideoSerco projectVideoDate={ProjectDate} />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Vie - Project Details Dark</title>
    </>
  )
}

export default Serco;
